// Colors
$text-primary: #292B2E;
$text-secondary: #6F6F6F;

$light-grey: #F3F3F3;
$stroke-grey: #EAECEF;
$hover-grey: #9A9A9A;
$label-blue-grey: #8793A6;
$light-blue-grey: #BCD2E1;
$blue-grey: #7E98A7;
$dark-blue-grey: #566E80;
$darker-blue-grey: #2D414D;
$pause-grey: #ACACAC;
$button-grey: #EBEBEF;
$link-blue: #255FDF;
$error-red: #B40020;

$dope-red: #EA0029;
$hover-red: #B40020;
$active-blue: #255FDF;
$scheduled-purple: #7B61FF;
$pending-yellow: #FFB800;
$background-blue: #F0F4F7;
$success-green: #00A82F;
$icon-black: #494B4E;
$white: #FFFFFF;


.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-grow {
  flex-grow: 1;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.top-align {
  align-items: flex-start;
}

.right-align {
  align-items: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.gap-xs {
  gap: 4px;
}
.gap-5 {
  gap: 5px;
}
.gap-10 {
  gap: 10px;
}
.gap, .gap-20 {
  gap: 20px;
}
.gap-40 {
  gap: 40px;
}
.gap-80 {
  gap: 80px;
}

.pad {
  padding: 20px;
}
.pad-tb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}
.pad-tb {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pad-lr {
  padding-left: 20px;
  padding-right: 20px;
}
.pad-tb-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pad-lr-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.pad-b {
  padding-bottom: 20px;
}
.pad-t {
  padding-top: 20px;
}

.pad-r {
  padding-right: 20px;
}

.pad-l {
  padding-left: 20px;
}

.pad-4, .pad-sm {
  padding: 4px;
}
.pad-sm-t {
  padding-top: 4px;
}

.pad-8 {
  padding: 8px;
}

.pad-16 {
  padding: 16px;
}

.padding-none {
  padding: 0 0 0 0;
}

.pad-row {
  padding: 10px 20px;

  &.sm {
    padding: 5px 10px;
  }
}

.margin-none {
  margin: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  margin-left: 0;
}

.margin {
  margin: 20px;
}

.margin-8 {
  margin: 8px;
}

.margin-bottom {
  margin-bottom: 20px;
}

.margin-top {
  margin-top: 20px;
}

.margin-right {
  margin-right: 20px;
}

.margin-left {
  margin-left: 20px;
}

.margin-tb {
  margin-top: 20px;
  margin-bottom: 20px;
}
.margin-tb-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.margin-tb-70 {
  margin-top: 70px;
  margin-bottom: 70px;
}
.margin-left-auto {
  margin-left: auto;
}

.margin-header {
  margin-top: 40px;
  margin-bottom: 20px;
}

.margin-4-b {
  margin-bottom: 4px;
}

.margin-4-t {
  margin-top: 4px;
}
.margin-0-t {
  margin-top: 0;
}

.margin-4-r {
  margin-right: 4px;
}

.margin-8-t {
  margin-top: 8px;
}

.margin-8-r {
  margin-right: 8px;
}

.margin-8-l {
  margin-left: 8px;
}

.margin-8-tb {
  margin-top: 8px;
  margin-bottom: 8px;
}

.margin-16 {
  margin: 16px;
}

.margin-16-tb {
  margin-top: 16px;
  margin-bottom: 16px;
}

.margin-16-b {
  margin-bottom: 16px;
}

.margin-20-b {
  margin-bottom: 20px;
}

.margin-10-b {
  margin-bottom: 10px;
}

.margin-40-b {
  margin-bottom: 40px;
}
.margin-40-l {
  margin-left: 40px;
}

.margin-16-t {
  margin-top: 16px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-upper {
  text-transform: uppercase;
}

.border {
  border: 1px solid $stroke-grey;
  border-radius: 8px;

  &.selected {
    border: 2px solid $active-blue;
  }
}

.border-bottom {
  border-bottom: 1px solid $stroke-grey;
}

.border-top {
  border-top: 1px solid $stroke-grey;
}

.border-right {
  border-right: 1px solid $stroke-grey;
}

.square-border {
  border: 1px solid $stroke-grey;
}

.rounded-border-top {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.rounded-border-bottom {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.to-right {
  display: flex;
  justify-content: flex-end;
}

.to-center {
  display: flex;
  justify-content: center;
}

.link {
  color: $link-blue;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.link-label {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  color: $link-blue;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.text-large {
  font-size: 20px;
}
.text-med {
  font-size: 16px;
}

.header-1 {
  color: $text-primary;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.header-2 {
  color: $text-primary;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.header-3 {
  color: $text-primary;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
}

.header-4 {
  color: $text-primary;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.header-5 {
  color: $text-primary;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
}

.header-6 {
  color: $text-primary;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.body-1 {
  color: $text-primary;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.non-bold {
  font-weight: 400;
}

.body-text {
  color: $text-secondary;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
}

.label {
  color: $label-blue-grey;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.22px;

  &.large {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--text-primary, #292B2E);

    &.blue-grey {
      color: $blue-grey;
    }

    &.label-blue-grey {
      color: $label-blue-grey;
    }
  }

  &.bold {
    color: #000;
    font-weight: 600;
  }

  &.color-text-secondary {
    color: $text-secondary;
  }

  &.optional {
    font-weight: 400;
  }
}

.hr {
  border: 1px solid $stroke-grey;
}

.color-black {
  color: #000;
}

.color-text-primary {
  color: $text-primary;
}

.color-text-secondary {
  color: $text-secondary;
}

.color-text-blue {
  color: $link-blue;
}

.color-text-pending-yellow {
  color: $pending-yellow;
}

.color-text-scheduled-purple {
  color: $scheduled-purple;
}

.color-text-red {
  color: $dope-red;
}

.color-text-error-red {
  color: $error-red;
}

.color-text-success-green {
  color: $success-green;
}

.color-text-label-blue-grey {
  color: $label-blue-grey;
}

.color-text-button-grey {
  color: $button-grey;
}

.color-icon-black {
  color: $icon-black;
}

.bold {
  font-weight: 600;
}

.help-text {
  color: $text-secondary;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */

  &.small {
    font-size: 11px;
  }

  &.large {
    font-size: 16px;
  }
}

.fine-print {
  color: $text-secondary;
  font-size: 10px;
  font-weight: 400;
  line-height: 120%;
}

.color-link {
  color: $link-blue;
}

.border-radius-8 {
  border-radius: 8px;
}

.background-blue-grey-shade-light {
  background: var(--blue-grey-shade-light, rgba(188, 210, 225, 0.10));
}

.background-white {
  background: $white;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.vertical-align-base {
  vertical-align: baseline;
}

.min-width-100 {
  min-width: 100px;
}

.min-width-240 {
  min-width: 240px;
}

.max-width-100 {
  max-width: 100px;
}
.max-width-200 {
  max-width: 200px;
}
.max-width-300 {
  max-width: 300px;
}
.max-width-400 {
  max-width: 400px;
}
.max-width-500 {
  max-width: 500px;
}
.max-width-600 {
  max-width: 600px;
}
.max-width-700 {
  max-width: 700px;
}
.max-width-800 {
  max-width: 800px;
}
.max-width-900 {
  max-width: 900px;
}
.max-width-1000 {
  max-width: 1000px;
}
.max-width-1200 {
  max-width: 1200px;
}
.min-width-100 {
  min-width: 100px;
}
.min-width-200 {
  min-width: 200px;
}
.min-width-300 {
  min-width: 300px;
}
.min-width-400 {
  min-width: 400px;
}
.min-width-500 {
  min-width: 500px;
}
.min-width-600 {
  min-width: 600px;
}
.min-width-700 {
  min-width: 700px;
}
.min-width-800 {
  min-width: 800px;
}
.min-width-900 {
  min-width: 900px;
}
.min-width-1000 {
  min-width: 1000px;
}
.min-width-1200 {
  min-width: 1200px;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.three-quarter-width {
  width: 75%;
}

.half-width {
  width: 50%;
}

.opacity-50 {
  opacity: 0.5;
}

.clickable {
  cursor: pointer;
  &.text {
    &:hover {
      text-decoration: underline;
    }
  }
}

.hoverable {
  cursor: pointer;
}

.pos-relative {
  position: relative;
}

.take-over {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    transition: opacity 0.4s ease-in-out;
  }
}

.circle-icon {
  line-height: 24px;
  font-size: 24px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrap-text {
  text-wrap: wrap;
}

.no-wrap-text {
  text-wrap: nowrap;
}

.clip-overflow {
  overflow: clip;
}

.line-height-1 {
  line-height: 1;
}

.filter-tab-bar {
  padding: 10px 4px;
  margin-right: 20px;

  &.selected {
    border-bottom: 3px solid $dope-red;
    border-radius: 0 0 3px 3px;
  }
}

.table-badge {
  padding: 2px 4px;
  margin-right: 4px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 700;
  color: #000;
  background-color: rgba(255, 255, 255, 0.8);

  &.blue {
    color: $link-blue;
  }

  &.invalid {
    color: $error-red;
    background-color: #EA00291A;
  }
}


.page-tabs {
  background-color: white;
  padding: 0 20px;
  display: flex;
  margin: -40px -40px 40px -40px;
  border-bottom: 1px solid #EAECEF;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .page-tab {
    flex-grow: 1;
    padding: 20px;
    font-size: 14px;
    font-weight: 400;
    color: black;
    opacity: 0.5;
    cursor: pointer;
    display: flex;
    align-items: center;

    &.active {
      font-weight: 600;
      opacity: 1;
      border-bottom: 2px solid #EA0029;
      cursor: default;
      pointer-events: none;
    }

    &.disabled {
      cursor: default;
      pointer-events: none;
    }

    &.valid::before {
      content: "\2713";
      color: white;
      background-color: green;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      display: inline-block;
      text-align: center;
      line-height: 16px;
      margin-right: 5px;
      font-weight: bold;
      font-size: 10px;
    }
  }
}

.dope-dropdown {
  color: #000;
  background: #fff;
  padding: 20px 20px 40px 20px;
  z-index: 1000;
  width: 350px;

  &.eddm_order {
    width: 250px;
    padding: 16px 0;
  }

  &.campaign, &.automation {
    padding: 4px 20px;
    width: 200px;
  }

  &.sweet_spot_analysis {
    padding: 20px;
  }
}

.dropdown-item-icon {
  color: #494B4E;
  font-size: 16px;
}


.CardItem {
  &__cover {
    width: 100%;
    font-size: 3vh;
    border: 2px solid var(--color-background-grey);
    border-radius: 8px;
    cursor: pointer;
    padding: 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      border: 2px solid var(--color-subtext);
    }

    & img {
      max-width: 100%;
    }
  }

  &__body {
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;

    padding: 0.5rem;

    & svg {
      flex-shrink: 0;
      margin-top: 4px;
    }

    & small {
      color: var(--color-subtext);
    }
  }

  & p {
    margin: 0;
  }

  &--selected {
    .CardItem__cover {
      border-color: rgba(0, 0, 0, 0.8);
    }
  }

  @media(min-width: 576px) {
    width: calc(50% - 1rem);
  }

  @media(min-width: 767px) {
    width: calc((100% / 3) - 1rem);
  }

  @media(min-width: 992px) {
    width: calc(25% - 1rem);

    // &:nth-child(5),
    // &:nth-child(6) {
    //   display: none;
    // }
  }

  @media(min-width: 1200px) {
    width: calc(20% - 1rem);

    // &:nth-child(5) {
    //   display: initial;
    // }
  }
}

// THIS OVERWRITES RSUITE CELLS, DISPLAYS PILLS IN THE MIDDLE OF THE CELL
.rs-table-cell-content{
  display: flex;
  align-items: center;
}

.dope-pill {
  height: 32px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  color: #292B2E;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  width: fit-content;
}

.dope-pill-small {
  height: 24px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 4px 8px;
}

.dope-pill-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clickable {
  cursor: pointer;
}

.pill-circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
}

.pill-circle-small {
  width: 10px;
  height: 10px;
  margin-right: 4px;
}

// centers text with circle
.pill-text {
  // margin-top: -2px; // DID I BREAK IT? Seems better without it..
  display: flex;
  align-items: center;
  gap: 8px;
}

.pill-text-small {
  margin-top: 0;
}

.dope-pill.default {
  background: transparent;
  border: 1px solid #EAECEF;
}

.dope-pill.default:hover {
  background: #F0F4F740;
}

.dope-pill.active {
  background: #255FDF1A;
}

.pill-circle.active {
  background: #255FDF;
}

.dope-pill.standard {
  background: #BCD2E11A;
}

.pill-circle.standard {
  background: #BCD2E1;
}

.dope-pill.success {
  background: #00A82F1A;
}

.pill-circle.success {
  background: #00A82F;
}

.dope-pill.error {
  background: #EA00291A;
}

.pill-circle.error {
  background: #B40020;
}

.dope-pill.scheduled {
  background: #7B61FF1A;
}

.pill-circle.scheduled {
  background: #7B61FF;
}

.dope-pill.cancelled {
  background: #BCD2E11A;
}

.pill-circle.cancelled {
  background: #494B4E;
}

.dope-pill.pending {
  background: #FFB8001A;
}

.pill-circle.pending {
  background: #FFB800;
}

.dope-pill.tag {
  background: #FFFFFF;
  height: 28px;
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
  color: #494B4E;
  border: 1px solid #EAECEF;

  &.hoverable {
    &:hover {
      background: #F0F4F7;
    }
  }
}

.pill-circle.tag {
  display: none;
}

.dope-pill.applied-filter {
  background: #F0F4F7;
  height: 28px;
  font-size: 14px;
  font-weight: 500;
  color: #255FDF;
}


.margin-80-b {
  margin-bottom: 80px;
}

.dope-sidebar {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  position: fixed;
  z-index: 99;
  justify-content: space-between;
  overflow-y: auto;
}

.nav-item {
  color: #6F6F6F !important;
  white-space: nowrap;
  overflow: hidden;
  height: 56px;
  padding-left: 24px !important;
  text-decoration: none;

  &.selected-nav {
    color: #000000 !important;
    font-weight: 700;
    padding-left: 16px !important;
  }
}


.menu-nav-item {
  font-size: 12px;
  .rs-dropdown-toggle {
    font-weight: 600 !important;
    font-size: 14px !important;
    padding-left: 30px !important;

    & > .rs-icon:not(.rs-dropdown-toggle-caret) {
      left: 8px !important;
    }
  }



  .nav-item {
    height: 32px;

    &.selected-nav {
      padding-left: 24px !important;
    }
  }

  &.rs-dropdown-expand {
    .rs-dropdown-toggle-caret {
      transform: rotate(0deg) !important;
      display: flex;
    }
  }

  &.rs-dropdown-collapse {
    .rs-dropdown-toggle-caret {
      transform: rotate(-90deg) !important;
      display: flex;
    }
  }

  .nav-text {
    margin-left: 6px;
    &.selected-text {
      color: #EA0029;
    }
  }

}

.nav-icon {
  font-size: 24px;
  margin-right: 8px;
  color: #6F6F6F !important;

  &.selected-icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: #EA0029;
    color: #FFFFFF !important;
  }
}

.nav-text {
  font-size: 14px;
  margin-top: 4px;

  &.selected-text {
    margin-top: 0;
    font-size: 14px;
  }
}

.help-center-icon {
  margin-top: -1px;
}

.dope-checkbox {
  display: flex;
  align-items: top;

  .boolean-wrapper {
    flex: 0 0 auto;
    margin-right: 10px;

    input {
      margin-top: 4px;
      cursor: pointer;
    }
  }
}

.rich-text-view {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  img {
    max-width: 100%;
  }

  iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1.4rem;
  }

  h5 {
    font-size: 1.2rem;
  }

  h6 {
    font-size: 1rem;
  }
}

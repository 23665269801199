.cluster-marker {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 100%;
  border: 4px solid rgba(0, 0, 0, 0.5);
  color: #fff;
  // font-size: 1.5rem;
  // height: 30px;
  // line-height: 30px;
  // text-align: center;
  // width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-weight: bold;
}

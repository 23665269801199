.dope-player-wrapper {
  width: 480px;
  height: 270px;
  margin: auto;
  border-radius: 8px;
  overflow: hidden;
}

.react-player {
  background: #494B4E;
}

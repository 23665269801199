.postcard-design-form-wrapper,
.goodie-design-form-wrapper {
  h5 {
    color: #292B2E;
    margin-top: 2rem;

    span {
      color: #346ae1;
      background-color: #f2f4f5;
      border-radius: 4px;
      padding: 0.2rem 0.5rem;
    }
  }

  a {
    cursor: pointer;
    text-decoration: underline;
  }

  .rs-form-group {
    margin: 1rem 0;
  }

  .instructions {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  .page-actions {
    text-align: right;
  }
}

.postcard-basics {
  max-width: max(50%, 400px);
}

.postcard-design-upload {
  display: flex;
  flex-wrap: wrap;

  justify-content: center;

  align-items: center;
  gap: 2rem;

  margin: 2rem 0;


  & .presentation {
    height: 100%;
  }

  @media screen and (min-width: 960px) {
    justify-content: flex-start;
  }
}

.mailer-type-selector {
  display: flex;
  gap: 1rem;
  align-items: flex-start;

  & .mailer-type-option {
    display: flex;
    flex-direction: column;

    align-items: center;
    cursor: pointer;

    gap: 0.5rem;
    padding: 1rem;
    height: 100px;
    width: 100px;
    margin: 0.5rem 0;

    border: 2px solid #EAECEF;
    border-radius: 8px;

    &:hover {
      border: 2px solid #0088ff;
    }

    &.selected {
      border: 2px solid #0088ff;
    }
  }
}

.postcard-design-upload-area {
  display: flex;
  flex-direction: column;


  justify-content: center;
  align-items: center;

  height: 100%;

  padding: 2rem;
  border: 1px dashed #666;
  border-radius: 8px;

  outline: none;
  transition: border .2s ease-in-out;
  text-align: center;

  cursor: pointer;

  &:hover {
    border: 1px dashed #000;
  }

  & h6 {
    margin-bottom: 0.5rem;
  }

  & b {
    margin-top: 1rem;
    display: block;
  }

  & p {
    margin: 0;
  }

  & button {
    margin-top: 1rem;
  }
}


.handwritten-card-design-preview,
.postcard-design-preview {
  max-width: 100%;
  width: 100%;

  max-height: 100%;

  height: 100%;

  position: relative;

  & .postcard-design-preview-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  & img,
  & .postcard-design-preview-placeholder {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
  }

  @media screen and (min-width: 960px) {
    max-width: calc(50% - 1.5rem);
    width: calc(50% - 1.5rem);
  }
}

.postcard-back-preview {
  .postcard-design-preview {
    &::before {
      content: '';
      position: absolute;
      width: calc(1384 / 2775) * 100%;
      height: calc(935 / 1875) * 100%;
      background-color: white;
      bottom: 0;
      right: 0;
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      width: calc(816 / 2775) * 100%;
      height: auto;
      background-color: white;
      top: 6%;
      left: 65.5%;
      aspect-ratio: 1 / 1;
      opacity: 0;
    }
  }

  &.hot_lead:not(.in-revision) {
    .postcard-design-preview {
      &::after {
        opacity: 1;
        background-image: url('./../images//hot-leads.svg');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  &.postcard--eddm {
    .postcard-design-preview {
      &::before {
        opacity: 0;
      }

      &::after {
        content: '';
        position: absolute;
        background-color: #fff;
        background-image: url('../images/eddm_indicia.jpg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        bottom: 0;
        right: 0;
        top: auto;
        left: auto;
        opacity: 1;

        aspect-ratio: 3.79 / 1.42;
      }
    }

    &.postcard--eddm--6_5x9 {
      .postcard-design-preview {
        &::after {
          width: calc(100% / 2775px) * 900px;
          height: auto;
        }
      }
    }

    &.postcard--eddm--6_5x11 {
      .postcard-design-preview {
        &::after {
          width: calc(100% / 3375px) * 900px;
          height: auto;
        }
      }
    }

    &.postcard--eddm--8_5x11 {
      .postcard-design-preview {
        &::after {
          width: calc(100% / 3375px) * 900px;
          height: auto;
        }
      }
    }
  }
}

.postcard-front-preview {
  .postcard-design-preview {
    &::after {
      content: '';
      position: absolute;
      width: calc(449 / 2775) * 100%;
      height: auto;
      background-color: white;
      top: 70.8%;
      left: 80.2%;
      aspect-ratio: 1 / 1;
      opacity: 0;
    }
  }

  &.hot_lead:not(.in-revision) {
    .postcard-design-preview {
      &::after {
        opacity: 1;
        background-image: url('./../images//hot-leads.svg');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
}

.postcard-design-preview-wrapper {
  img {
    position: relative;
  }

  &::after {
    transition: opacity 0.5s;
    border: 5px solid rgba(255, 0, 0, 0.5);
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    z-index: 3;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
}


.postcard-design-crop {
  width: 100%;
  max-height: 100%;
  height: 100%;
}

.postcard-design-crop-controls {
  max-width: 100%;
  width: 100%;

  max-height: 100%;
  height: 100%;
  position: relative;

  @media screen and (min-width: 960px) {
    max-width: calc(50% - 1.5rem);
    width: calc(50% - 1.5rem);
  }
}

.postcard-design-crop-actions {
  display: flex;
  border-radius: 50px;

  align-items: center;

  background-color: #fff;
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  left: 50%;
  margin: 0 auto;
  width: auto;

  transform: translate(-50% , -50%);

  box-shadow: 0px 6px 6px hsla(0, 0%, 73%,.25);

  & button {
    background-color: #fff;
    padding: 5px 10px;
    font-size: small;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }

  & button:first-child {
    border-radius: 50px 0 0 50px;
  }

  & button:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  & small {
    padding: 0.5rem;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  & button:last-child {
    border-radius: 0 50px 50px 0;
    border-left: 0;
  }

  & button:last-child:first-child {
    border-radius: 50%;
  }
}

.postcard-design-preview-placeholder {
  text-align: center;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  justify-items: center;

  background-color: #f8fbfc;

  gap: 2rem;
  width: 100%;

  font-size: 300%;
  font-weight: bold;
  color: #8793a6;
  & svg {
    max-width: 100px;
  }
}

.postcard-preview {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;

  & img {
    max-width: 50%;
  }
}

.handwritten-card-design-back {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;

  justify-content: space-between;

  & > * {
    width: 100%;
  }

  .input-footer {
    display: flex;
    justify-content: space-between;
  }
}

.handwritten-card-form-wrapper {
  .handwritten-basics {
    max-width: max(50%, 400px);
  }
}

.handwritten-card-cover-form {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  margin-bottom: 2rem;

  // Tabs
  & > *:nth-child(2) {
    max-width: calc(50% - 1.5rem);
    width: calc(50% - 1.5rem);
  }

  .handwritten-card-form-tab {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    & .postcard-design-upload {
      margin-bottom: 0;
    }

    & .CardItem {
      width: 100%;
    }
  }
}

.handwritten-card-text-preview {
  border: 1px solid #f0f4f7;
  padding: 1rem;
  min-height: 350px;

  display: grid;

  grid-template-rows: auto 1fr auto;

  & .handwritten-card-text-preview-body {
    max-width: 100%;
    word-break: break-word;
  }

  & .handwritten-card-text-preview-signature {
    text-align: right;
  }

  & .handwritten-card-text-preview-header,
  & .handwritten-card-text-preview-footer {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-height: 50px;
    }
  }

  & .handwritten-card-text-preview-header {
    justify-content: flex-start;
    margin-bottom: 1rem;
  }

  & .handwritten-card-text-preview-footer {
    margin-top: 1rem;
  }

  & .handwritten-card-text-preview-address {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    img {
      max-height: 100px;
    }
  }

  &.mail-piece-preview {
    min-height: 280px;
  }
}

.handwritten-card-font,
.handwritten-card-stock {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.handwritten-card-stock {
  padding-top: 2rem;
  .CardItem {
    width: 100%;
  }
}

.handwritten-card-review-header {
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}

.handwritten-card-extras-form,
.handwritten-card-review-details,
.handwritten-card-review-summary {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding-bottom: 2rem;

  & > * {
    width: calc(50% - 1rem);
  }

  .handwritten-card-design-preview {

    & img {
      object-fit: cover;
    }

    max-height: 100%;
    @media screen and (min-width: 960px) {
      max-width: calc(100% - 1.5rem);
      width: calc(100% - 1.5rem);
    }
  }

  .character-count {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
    color: #6F6F6F;
  }

  .handwritten-card-sender-name {
    margin-bottom: 0 !important;
  }
}

.handwritten-card-review-acknowledgement {
  background-color: #f8fbfc;
  border-radius: 4px;
  padding: 1rem 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.handwritten-stock-cards,
.handwritten-fonts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;

  .CardItem {
    width: 100%;
  }
}

.handwritten-card-setup {
  .rs-form-group {
    margin: 2rem 0;
    width: 100%;

    @media(min-width: 992px) {
      width: 50%;
    }
  }
}

.handwritten-card-gift-card-select {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 1rem;

  img {
    max-height: 150px;
  }

  & > * {
    width: calc(50% - 1rem);
  }
}

.dope-checkbox {
  margin: 1rem 0;
}

.handwritten-card-gifts {
  margin-bottom: 2rem;

  .rs-picker {
    margin-bottom: 1rem;
  }
}

.upload-drawer {
  margin-top: 3rem;

  h2 {
    font-size: 1rem;
    margin: 1rem 0;
  }

  .rs-radio-group {
    margin-bottom: 1rem;
  }

  .space-between {
    margin-top: 1rem;
  }
}


.goodie-design-form-wrapper {
  [role="presentation"] {
    max-width: 400px;
    margin: 1rem 0;
  }

  .goodie-basics {
    max-width: max(50%, 400px);
  }
}

.design-revision-page {
  background-color: #fff;
  margin-bottom: 4rem;

  & .page-header {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;

    & > div {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    & .badge {
      color: #346ae1;
      background-color: #f2f4f5;
      border-radius: 4px;
      padding: 0.2rem 0.5rem;
      font-weight: bold;
    }
  }

  .design-revision-actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
  }

  .design-revision-dropdown {

    border: 1px solid hsl(0, 0%, 80%);

    border-radius: 4px;
    button {
      background-color: #fff;
      height: 38px;
    }

    .rs-dropdown-toggle-icon {
      margin: 0;
    }
  }

  .design-version {
    &-preview {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      margin: 1rem 0;

      img {
        max-width: 100%;
        object-fit: cover;
        border: 2px solid transparent;
        border-radius: 4px;
        cursor: pointer;

        &.selected {
          border: 2px solid rgba(52, 106, 225, 0.5);
        }

        &:first-child {
          &::before {
            content: "Front";
          }
        }
      }
    }

    &-content {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: 1fr auto;
      flex-wrap: wrap;
      align-items: stretch;
      gap: 24px;

      margin: 24px 0;

      max-height: 800px;

      .design-version-viewer {
        display: grid;
        grid-template-rows: auto 1fr;

        max-height: 800px;

        img {
          max-height: 200px;
          max-height: 100%;
        }

        .postcard-cover {
          max-height: 600px;
          max-width: 100%;
        }
      }
    }
  }

  .design-version-actions {
    width: 100%;

    .design-version-actions-placeholder {
      max-width: 70%;
    }

    & > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .instruction {
    color: #8793a6;
    max-width: 820px;
    font-size: 1rem;
  }

  .design-version-comments {
    display: grid;
    grid-template-rows: auto 1fr auto;
    max-height: 800px;

    border: 1px solid #ced4da;
    border-radius: 8px;
    max-width: 400px;
    min-width: 400px;

    h4 {
      text-align: center;
    }

    &-header {
      padding: 0.5rem;
      border-bottom: 1px solid #ced4da;
    }

    &-body {
      overflow: auto;
    }

    &-footer {
      display: flex;
      flex-direction: column;
    }

    &-area {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;

      background-color: #eee;
      border-top: 1px solid #ced4da;

      padding: 0.3rem;

      & div {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;

        & span {
          width: 30px;
          height: 25px;

          display: flex;
          justify-content: center;
          align-items: center;

          border-radius: 8px;
          background-color: #fff;
          font-weight: 600;
        }
      }

      & p {
        margin: 0;
      }
    }

    &-textbox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem;
      border-top: 1px solid #ced4da;
      gap: 0.5rem;

      textarea {
        border: 0;
        padding: 0.5rem;
        width: 100%;
        resize: none;
      }
    }
  }

  .version-comment {
    padding: 0.5rem;

    &:not(:last-child) {
      border-bottom: 1px solid #ced4da;
    }

    &-header {
      margin-bottom: 0.5rem;
      & div {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: flex-start;

        & > p {
          margin: 0;
        }

        small {
          color: #6c757d;
        }
      }
    }


    &-area {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      & small {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-right: 0.5rem;
        border: 1px solid #ced4da;
        width: 25px;
        height: 25px;

        border-radius: 4px;
      }
    }
  }

  .design-version-attachments {
    display: flex;
    gap: 2rem;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem 0;

    .design-version-attachments-input {
      min-width: 350px;

      [role="presentation"] > div {
        min-height: 250px;
      }
    }

    .design-version-attachments-list {
      display: flex;
      gap: 1rem;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;

      .design-version-attachment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        padding: 0.5rem 1rem;
        box-shadow: 0px 1px 1px hsla(0, 0%, 73%, .35);

        width: 100%;

        max-width: 350px;

        p {
          margin: 0;
          flex: 1;

          color: #6c757d;
        }

        &-actions {
          display: flex;
          gap: 1rem;
          align-items: center;
          justify-content: space-between;

          button {
            background: transparent;
          }
        }
      }
    }
  }

  .design-version-status {
    background-color: #f8fbfc;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 2rem;
    color: #6c757d;
  }
}

.design-image {
  width: 465px;
  height: fit-content;
  border-radius: 13px;
}

.image-preview-container {
  position: relative;
  text-align: center;
  color: white;
  cursor: pointer;
}

.image-preview {
  width: 162px;
  height: 109px;
  border-radius: 3px;
  margin-right: 10px;
  opacity: 0.9;

  &.selected {
    border: 2px solid #255FDF;
  }

  & .handwritten-card-text-preview {
    min-height: 100%;
    img {
      display: none;
    }
  }


}

.sticky-footer {
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  margin-top: 4rem;
  box-shadow: 0px -5px 5px -5px rgba(0, 0, 0, 0.2);
  left: 0;
  gap: 2rem;
  align-items: center;

  .sticky-wrapper {
    display: flex;
    gap: 1rem;
    align-items: center;
    width: 100%;
    padding: 1rem 4rem;
    padding-left: calc(200px + 4rem);
    justify-content: space-between;
    gap: 2rem;

    & button {
      width: 100%;
      max-width: 400px;
    }
  }
}

.dope-dropdown {
  &.design-revision-dropdown {
    padding: 20px;

    a {
      text-decoration: none;
      color: inherit;
    }

    p, a {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: flex-start;
      font-weight: bold;
      margin-bottom: 0.5rem;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.image-preview-overlay-text {
  width: 56px;
  height: 29px;
  border-radius: 15px;
  padding: 4px 8px;
  color: #000000;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 600;
}



.dope-card-select {

  &.horizontal {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .dope-icon-card {
      min-width: 420px;
    }
  }

}

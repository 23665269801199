@import "../utility.scss";

.image-container {
  position: relative;
  text-align: center;
  color: white;
  cursor: pointer;
}

.image-preview {
  width: 162px;
  height: 109px;
  border-radius: 3px;
  margin-right: 10px;
  opacity: 0.9;

  &.selected {
    border: 2px solid #255FDF;
  }
}

.selected-image {
  width: 465px;
  height: 280px;
  border-radius: 13px;
}

.overlay-text {
  width: 56px;
  height: 29px;
  border-radius: 15px;
  padding: 4px 8px;
  color: #000000;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 600;
}

.mailer-data-wrapper {
  border-radius: 8px;
  background: rgba(188, 210, 225, 0.1);
  padding: 10px 20px;
  margin: 20px 0;
}

.mailer-data-header{
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.status-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 5px 0 0;

  // scheduled cancelled processing duplicate undeliverable sent delivered error
  &.scheduled {
    background-color: $scheduled-purple;
  }

  &.printed {
    background-color: $icon-black;
  }

  &.sent {
    background-color: $active-blue;
  }

  &.delivered {
    background-color: $success-green;
  }

  &.undeliverable, &.error {
    background-color: $dope-red;
  }

  &.cancelled {
    background-color: $label-blue-grey;
  }

  &.processing {
    background-color: $pending-yellow;
  }

  &.duplicate {
    background-color: #FFC107;
  }
}

.badge {
  padding: 4px 8px;
  font-size: 11px;
  font-weight: 700;
  color: #255FDF;
  background: #F0F4F7;
  border-radius: 8px;
  width: fit-content;
}

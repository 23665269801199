.object-map {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
}

.object-map__content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.object-map__column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.object-map__column:nth-child(odd) {
  margin-right: 5px;
}

.object-map__column:nth-child(even) {
  margin-left: 5px;
}

.object-map__field {
  display: flex;
  align-items: center;
  gap: 10px;
}

.object-map__label {
  font-weight: 500;
  width: 150px;
  text-align: right;
  padding-right: 10px;
  margin: 0;
  padding: 10px 16px 10px 16px;
  border-radius: 4px;
  background-color: #F8F9FA;
}

.object-map__select {
  flex-grow: 1;
}

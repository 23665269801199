.tracking-number-drawer {
  display: grid;
  grid-template-rows: 1fr auto;

  .rs-form-group {
    margin-top: 1rem;
  }

  .number-search {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 1rem;
  }

}

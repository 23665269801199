.bordered-section {
  border: 1px solid #EAECEF;
  border-radius: 8px;
  padding: 8px;
}

.payment-method-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid #EAECEF;
  border-radius: 8px;
  width: 280px;
  margin-bottom: 5px;
  justify-content: space-between;

  &.selectable {
    width: 420px;
  }
}

.credit-card-logo-wrapper {
  width: 60px;
  height: 41.14px;
  border: 1.71px solid #D9D9D9;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}

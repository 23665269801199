
.selection-pill {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 2px 6px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  font-size: 12px;

  &:last-child {
    margin-right: 0;
  }

  .selection-pill-remove {
    margin-left: 6px;
    color: white;
    background-color: black;
    border: 1px solid black;
    font-weight: 700;
    font-size: 10px;
    padding: 2px;

    &:hover {
      color: black;
      background-color: white;
    }

    .rs-btn-icon.rs-btn-xs>.rs-icon {
      font-size: 10px;
    }
  }
}

.selection-pill-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0;
}


// Data Sources

.data-source-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  font-family: "IBM Plex Sans", sans-serif;
}

.data-source-item {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 160px;
  min-height: 120px;
  padding: 15px;
  border: 2px solid #eee;
  border-radius: 4px;
  background-size: cover;
  background-position: center;
  background: white;
  cursor: pointer;
  overflow: hidden;

  &.data-source-item--selected {
    border: 2px solid var(--ui-selector-testing-blue, #255FDF);
    box-shadow: 0px 6px 6px 0px rgba(186, 186, 186, 0.25);
  }

  &.data-source-item--disabled {
    pointer-events: none;
    cursor: normal;
  }

  .data-source-item__icon {
    width: 70x;
    height: 70px;

    img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .data-source-item__label {
    font-size: 11px;
    font-weight: 700;
    text-align: center;
    line-height: 120%;
    letter-spacing: 0.22px;
    color: #000;
  }

  .data-source-item__connect_button {
    padding: 6px 16px;
    width: 100%;
    margin: 10px 0 0 0;
    height: 32px;
    border-radius: 20px;
    background: none;
    color: #292B2E;
    border: 1px solid #F3F3F3;
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.22px;

    &.data-source-item__connect_button--connected {
      background: var(--ui-icons-cards-black, #494B4E);
      border: none;
      color: var(--ui-cards-white, var(--color-neutrals-00, #FFF));
    }

    &.data-source-item__connect_button--disabled {
      background: none;
      border: none;
      pointer-events: none;
      cursor: default;
    }
  }

}

// Connections

.connection-form, .connection-form__success {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 520px;
  margin: 8px;
}

.connection-form.loading {
  opacity: 0.5;
  pointer-events: none;
}

.connection-form.success button {
  display: none;
}

.connection-form h3 {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 16px;
}

.connection-form__field {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.connection-form__field label {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 4px;
}

.connection-form__field input {
  font-size: 14px;
  color: #333;
  padding: 6px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.connection-form__field input:focus {
  border-color: #2196f3;
  outline: none;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.2);
}

.connection-form__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.connection-form button {
  font-size: 14px;
  font-weight: 600;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-start;
  min-width: 115px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.5);
  }

  &.connection-form__cancel_button {
    background-color: #fff;
    border: 1px solid #ccc;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &.connection-form__connect_button {
    background-color: #2196f3;
    color: #fff;

    &:hover {
      background-color: #1e88e5;
    }
  }
}

.connection-form__success {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.connection-form__success button {
  background-color: #2196f3;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 50%;
}

.connection-form__success .message {
  width: 50%;
}

.connection__content__actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1rem;
}

.connection__content__actions button {
  padding: 0.5rem 1rem;
  border: 2px solid #333;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #fff;
  color: #333;
  border: 2px solid #333;
  min-width: 175px;
}

.connection__content__actions button:hover {
  background-color: #333;
  color: #fff;
}

// Subscriptions

.subscription-header {
  font-size: 22px;
  line-height: 34px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.subscription-summary {
  padding: 25px 40px;
  margin-bottom: 40px;
  //margin-top: 20px;
  border-radius: 8px;
  border: 2px solid var(--ui-input-stroke, #EAECEF);
  background: var(--color-neutrals-00, #FFF);
  box-shadow: 0px 6px 6px 0px rgba(186, 186, 186, 0.25);
}

.subscription-summary_trigger {
  color: #000;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.22px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.subscription-summary_header {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.subscription-summary_type-label {
  color: var(--ui-selector-testing-blue, #255FDF);
  font-weight: 600;
}

.subscription-summary_logic-label {
  text-transform: uppercase;
  display: inline-block;
  padding: 4px;
  min-width: 35px;
  border: 1px solid var(--ui-input-stroke, #EAECEF);
  border-radius: 4px;
  margin: 0 10px;
}

.subscription-summary_operator-label {
  @extend .subscription-summary_logic-label;
  text-transform: none;
}

.subscription-summary_field-label {
  display: inline-block;
  padding-bottom: 2px;
  border-bottom: 2px solid var(--ui-selector-testing-blue, #255FDF);
}

.subscription-summary_conditions-summary {
  padding: 20px;
  padding-bottom: 0;
  color: #000;
}

@for $i from 0 through 6 {
  .condition-#{$i} {
    margin-left: $i * 45px;
  }
}


// QUERY BUILDER OVERRIDES

.query-builder__container.disabled .queryBuilder {
  opacity: 0.5;
  pointer-events: none;
}

.query-builder__container .queryBuilder .ruleGroup-container {
  display: flex;
  flex-direction: column;
}

.query-builder__container .queryBuilder .ruleGroup-header {
  order: 2;
}

.query-builder__container .queryBuilder .ruleGroup-body {
  order: 1;
}

.react-date-picker__wrapper {
  background-color: white;
}


.conditions-wrapper .condition-group {
  background: white;
  padding: 0 0 10px 0;
  border: none;
}

.query-builder__container .queryBuilder .ruleGroup:not(.queryBuilder > .ruleGroup) {
  background-color: rgba(204,204,204, 0.15);
  // border: 1px solid rgba(204,204,204, 0.9);
  border-right: none;
  border-bottom: none;
  border-top: none;
  border-radius: 4px;
  padding: 10px;
}


.condition-input {
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ruleGroup .rule.condition-item {
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
}

.condition-item > * {
  flex: 12;
}

.condition-item > .condition-item-remove {
  flex: 1;
  height: 38px;
  width: 38px;
  border-color: hsl(0, 0%, 80%);
  border-width: 1px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.condition-item-remove__icon {
  cursor: pointer;
  font-size: 16px;
  line-height: 18px;
}

.condition-item-add {
  border-color:rgb(221, 221, 221);
  border-width: 1px;
  border-radius: 3px;
  border-bottom-style: inset;
  border-right-style: inset;
  background-color: white;
}

.and-or-select {
  border-color: hsl(0, 0%, 80%);
  border-width: 1px;
  border-radius: 4px;
}

.ruleGroup-remove {
  background-color: transparent;
  padding: 0;
  border: none;
}

// UTIL

.squiggle {
  background: linear-gradient(to bottom, #0087ca 0%, #0087ca 100%);
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 2px 2px;
  color: inherit;
  text-decoration: none;
}

.squiggle.loading {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-15px);}}%3C/style%3E%3Cpath fill='none' stroke='%230087ca' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-size: auto 4px;
}

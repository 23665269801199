.overview {
  width: 100%;
  padding: 20px;

  .overview-section {
    border-radius: 8px;
    border: 1px solid #EAECEF;
    background: #FFFFFF;
    padding: 20px;
    margin-bottom: 20px;
  }

  .overview-header {
    font-weight: 600;
    font-size: 22px;
    color: #000000;
  }

  .to-do-item {
    padding: 10px 0;
    cursor: pointer;
    display: flex;
    align-items: center;

    .to-do-item-icon {
      font-size: 28px;
      margin-right: 12px;
      color: #8793A6;
      height: 100%;
      display: flex;
      justify-content: center;
    }

    .to-do-item-header {
      font-weight: 400;
      font-size: 16px;
    }

    .to-do-item-description {
      font-size: 11px;
      font-weight: 400;
      color: #8793A6;
    }
  }

  .to-do-item:hover {
    background: rgba(188, 210, 225, 0.1);
    margin: 0 -20px;
    padding: 10px 20px;

    .to-do-item-icon {
      color: #494B4E;
    }
  }

  .quick-actions {
    border-radius: 8px;
    padding: 32px 24px;
    background-color: #494B4E;
    color: #fff;
    margin-bottom: 20px;

    .overview-header {
      color: #fff;
    }

    .quick-links {
      color: #fff;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      padding: 32px 24px;
      border-radius: 8px;
      text-decoration: none;

      .quick-links-text {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .quick-links:hover {
      box-shadow: 0px 6px 6px 0px #00000040, 0px 1px 1px 0px #00000020;
    }
  }


  .recent-activity-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: flex-start;
    margin: 0 -20px;

    position: relative;
    &:after {
      transition: opacity 1.5s;
      content: "";
      position: absolute;
      z-index: 1;
      top: 60px;
      left: 0;
      pointer-events: none;
      background-image: linear-gradient(to bottom, rgba(255,255,255,0) 40%, rgba(255,255,255, 1) 100%);
      width: 100%;
      height: calc(100% - 60px);
    }

    max-height: 100px;
    overflow: hidden;
    transition: max-height 1.5s cubic-bezier(0, 1, 0, 1);
    &:hover {
      max-height: 1000px;
      transition: max-height 1.5s ease-in-out;
      background: rgba(188, 210, 225, 0.1);
      &:after {
        opacity: 0;
      }
    }

    .recent-activity-icon {
      min-width: 30px;
      height: 30px;
      border-radius: 50%;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      box-shadow: 0px 2px 2px 0px rgba(186, 186, 186, 0.25);
    }

    .recent-activity-item-description {
      padding-top: 5px;
    }
  }

  .account-user-name {
    font-size: 16px;
    font-weight: 600;
    color: #292B2E;
  }

  .your-team-item {
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    justify-content: space-between;
    align-items: center;

    .your-team-user-info {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .account-user-initials {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      color: #FFFFFF;
      margin-right: 10px;
    }

    .your-team-icon {
      color: #255FDF;
      font-size: 24px;
      cursor: pointer;
    }
  }

  .recent-activity-item-super {
    font-size: 11px;
    font-weight: 400;
    color: #292B2E;
    display: flex;
    flex-direction: row;
  }

  .recent-activity-date {
    color: #8793A6;
    margin-left: 5px;
  }

  .recent-activity-body-text {
    color: #292B2E;
    font-size: 14px;
    font-weight: 400;
  }

  .recent-activity-link {
    color: #255FDF;
    margin: 0 3px;
    cursor: pointer;
  }

  .recent-activity-creator-initials {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
    margin-right: 10px;
  }

  .recent-activity-creator-name {
    font-size: 11px;
    font-weight: 400;
    color: #8793A6;
  }

  .pending-action-header {
    font-size: 16px;
    font-weight: 600;
    color: #292B2E;
    margin-left: 20px;
  }

  .pending-action-description {
    font-size: 14px;
    font-weight: 500;
    color: #292B2E;
    flex: 3;
    padding: 0 10px;
  }

  .pending-action-description-detail {
    font-size: 11px;
    font-weight: 400;
    color: #8793A6;
  }

  .stragety-text{
    font-size: 16px;
    font-weight: 400;
    color: #8793A6;
  }
}

.subscription-wrapper {
  padding: 16px 40px;
  border-radius: 16px;
  box-shadow: 0 6px 6px 0 #BABABA40;
  border: 1px solid #EAECEF;
  height: 126px;
}

.info-box {
  background-color: #136EC226;
  padding: 15px 20px;
}

.signature-box {
  border: 1px solid #F3F3F3;
  box-shadow: 0px 6px 6px 0px #BABABA40;
}

.color-text-info {
  color: #136EC2 !important;
}

.selected {
  border: 2px solid #255FDF;
}

.unselected {
  border: 1px solid #EAECEF;
}

.current-plan-wrapper {
  border: 2px solid #EA0029;
  cursor: not-allowed;
}

.subscription-logo {
  font-weight: 900;
  font-size: 32px;
  font-family: Montserrat, serif;
  line-height: 120%;
  font-style: normal;
}

.monthly-price {
  font-weight: 700;
  font-size: 20px;
  color: #000000;
}

.selector-price-interval {
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}

.discounted-price-interval {
  text-decoration: line-through;
}

.select-text {
  font-weight: 700;
  font-size: 11px;
  color: #255FDF;
  background: #F0F4F7;
  border-radius: 4px;
  padding: 2px 4px;
  width: fit-content;
}

.current-plan {
  font-weight: 700;
  font-size: 11px;
  color: #EA0029;
  background: rgba(234, 0, 41, 0.1);
  border-radius: 4px;
  padding: 2px 4px;
  width: fit-content;
}

.subscription-start-date {
  font-weight: 700;
  font-size: 11px;
  color: #255FDF;
}

.lil-taste {
  color: #DB6540;
}

.dope {
  color: #DB9940;
}

.doper {
  color: #999999;
}

.dopest {
  color: #EBCC53;
}

.freebie {
  color: #4A90E2;
}


// rsuite overrides
.rs-panel.rs-panel-collapsible {
  width: 240px;
}

.rs-panel.rs-panel-in.rs-panel-collapsible {
  width: 100%;
}

.rs-panel-header.rs-heading {
  padding-left: 0;
  padding-bottom: 0;
}

.rs-panel-btn {
  color: #255FDF;
  font-size: 14px;
  font-weight: 600;
  padding-left: 0;
  padding-bottom: 0;
}

.rs-panel-btn:hover, .rs-panel-btn:active, .rs-panel-btn:focus {
  background-color: inherit;
  color: inherit;
}


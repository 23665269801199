@import "../utility.scss";

.mode-circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;

  &.active {
    background: $active-blue;
  }

  &.pending {
    background: $pending-yellow;
  }

  &.paused {
    background: $pause-grey;
  }
}

.archived-text {
  color: $pause-grey;
  font-size: 14px;

}

.radio-button-margin-offset {
  margin-left: 26px;
}

.automation-form-group {
  width: 420px;
}

/* Override default react-date-picker styles */
.react-date-picker {
  display: inline-block;
  font-size: 14px;
  position: relative;
  color: hsl(0, 0%, 20%);
  width: 100%;
}

.react-date-picker svg {
  stroke: #ced4da;
}

.react-date-picker__wrapper {
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  padding: 8px 10px;
}

// .react-date-picker__inputGroup {

// }

.react-date-picker__inputGroup__input {
  color: hsl(0, 0%, 20%);
}

.react-date-picker__inputGroup__leadingZero {
  font-size: 14px;
}

.react-date-picker__inputGroup__separator {
  font-size: 14px;
  margin: 0 2px;
}

.react-date-picker__calendar {
  background-color: white;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 2px;
  position: absolute;
  user-select: none;
  z-index: 100;
  inset: 100% 0 0 auto !important; /* Top, Right, Bottom, Left */
}

.react-date-picker__calendar .react-calendar {
  border-radius: 4px;
  border-color: #ced4da;
}

.react-date-picker__calendar__arrow {
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: center;
  position: absolute;
  z-index: 1;
}

.react-date-picker__button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  position: relative;
  z-index: 1;
}

.react-date-picker__button:enabled:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.react-date-picker__button__icon {
  display: flex;
  font-size: 14px;
  justify-content: center;
}

.react-date-picker__button--clear {
  position: absolute;
  right: 0;
}

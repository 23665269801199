
:root {
  --designer-sidebar-width: 240px;
  --designer-sidebar-collapsed-width: 72px;

  --designer-panel-width: 360px;

  --designer-panel-collapsed-width: 320px;

  --layers-panel-width: 320px;
}

.Editor {
  width: 100%;
  height: 100%;

  .app__sidebar {
    display: flex;
    flex-direction: row;
    border-right: 1px solid var(--color-border);

    min-width: calc(var(--designer-sidebar-collapsed-width) + var(--designer-panel-collapsed-width));
    width: calc(var(--designer-sidebar-collapsed-width) + var(--designer-panel-collapsed-width));
    max-width: calc(var(--designer-sidebar-collapsed-width) + var(--designer-panel-collapsed-width));

    max-height: calc(100vh - 68px);

    // @media(min-width: 992px) {
    //   min-width: calc(var(--designer-sidebar-width) + var(--designer-panel-width));
    //   width: calc(var(--designer-sidebar-width) + var(--designer-panel-width));
    //   max-width: calc(var(--designer-sidebar-width) + var(--designer-panel-width));
    // }

    & .go221062325 {
      display: none;
    }

    &.right-side-bar {
      width: var(--layers-panel-width) !important;
      max-width: var(--layers-panel-width) !important;
      min-width: var(--layers-panel-width) !important;
      right: 0;
      height: 100%;
      border-left: 1px solid var(--color-border);
      background-color: #fff;

      & .custom-layers {
        padding-top: 0.5rem;
        padding-left: 0.5rem;
        width: 100%;
        height: 100%;

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;

        gap: 1rem;
      }

      h2 {
        font-size: 1.3rem;
      }

      & .file-controls {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        padding: 0 1rem;

        &-buttons {
          display: flex;
          gap: 1rem;

          justify-content: space-between;
          align-items: center;
        }

      }
    }

    .polotno-side-panel-tab {
      &:hover {
        background-color: rgba(234, 0, 41, 0.3);
      }

      &.active {
        color: #fff;
        background-color: #EA0029;
      }
    }

    .polotno-panel-container {
      border: 0 !important;

      width: var(--designer-panel-collapsed-width);
      max-width: var(--designer-panel-collapsed-width);

      // @media(min-width: 992px) {
      //   width: var(--designer-panel-width);
      //   max-width: var(--designer-panel-width);
      // }

      & > div {
        width: 100%;
        height: 100%;

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;

        gap: 1rem;
      }

      h2 {
        font-size: 1.3rem;
      }

      & .custom-headers {
        button {
          appearance: none;
          display: block;
          background-color: transparent;
          outline: none;
          border: none;
          margin: 1rem 0;
        }
      }

      .tabs-wrapper {
        width: 100%;
        overflow-y: auto;
      }

      .templates-list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      .template-preview-wrapper {
        .image-wrapper {
          display: flex;
          gap: 1rem;
          cursor: pointer;

          img {
            width: calc(100% / 2 - 1rem);
            border: 1px solid #ccc;
          }

          &:hover {
            img {
              width: calc(100% / 2 - 1rem);
              border: 1px solid #424242;
            }
          }
        }
      }

      .qr-wrapper {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 2fr;
        overflow-y: auto;


        border-bottom: 1px solid var(--color-border);
        padding: 1rem 0;

        .qr-title {
          font-size: 1rem;
          font-weight: 600;
        }

        .sub-title {
          font-size: 11px;
          color: var(--color-subtext);
          font-weight: 400;
          line-height: 13px;
        }

        .qr-title, .sub-title {
          max-width: 170px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 0;
          .download-link, button {
            font-weight: 400;
            font-size: 11px;
            line-height: 13px;
            color: #0070D7;
            border: none;
            background-color: transparent;

            &.status {
              color: #FF0000;
            }
          }
        }

      }

      & .bp5-tabs {
        display: none;
      }

      & .bp5-button {
        display: none;
      }
    }
  }

  .bleed-button {
    background: transparent;
  }

  .layers {
    font-size: small;
    max-height: calc(100vh - 315px);
  }

  .photo-list {
    & > div:first-child {
      display: flex;
      justify-content: center;
      color: #EA0029;
      border: 1px solid #EA0029;
      background: #fff;
      text-align: center;

      cursor: pointer;
      padding: 0.8rem 0;

      & p {
        margin: 0;
      }

      &:hover,
      &:focus {
        outline: none;
        font-weight: bold;
      }
    }
  }

  .qr-code {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
    margin: 1rem 0;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem;


    &-image {
      img {
        max-width: 100px;
        border: 2px solid transparent;
        cursor: pointer;
        padding: 0;

        &:hover {
          border: 2px solid #EA0029;
        }
      }
    }

    &-content {
      width: 100%;
    }
  }

  .qr-code-list {
    & button {
      display: flex;
      width: 100%;
      justify-content: center;
      color: #EA0029;
      border: 1px solid #EA0029;
      background: #fff;
      text-align: center;
      border-radius: 0;

      cursor: pointer;
      padding: 0.8rem 0;

      & p {
        margin: 0;
      }

      &:hover,
      &:focus {
        outline: none;
        font-weight: bold;
      }
    }
  }
}

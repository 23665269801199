

.credit-summary {
  border: 1px solid #EAECEF;
  border-radius: 8px;
  padding: 20px;
  background: #FFFFFF;
  color: #292B2E;
  margin-bottom: 20px;

  .credit-summary-list {
    margin: 20px 0;

    .credit-summary-item {
      display: flex;
      justify-content: space-between;
      margin: 5px 0 10px;
      font-size: 16px;
    }

    .credit-summary-amount {
      font-weight: 600;
    }
  }
}

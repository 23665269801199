.font-item {
  width: 100%;
  font-size: 2.8rem;
  border: 2px solid #f0f4f7;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;

  & p {
    margin: 0;
  }

  &--selected {
    border-color: rgba(0, 0, 0, 0.8);
  }

  &--selectable {
    cursor: pointer;

    &:hover {
      border: 2px solid #868686;
    }
  }
}

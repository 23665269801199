.error-boundary {
  padding: 20px 40px;
  border: 2px solid #f00;
  border-radius: 8px;
  margin: 20px 0;

  .error-boundary_header {
    font-size: 22px;
    font-weight: bold;
  }

  .error-boundary_message {
    font-size: 18px;
  }
}

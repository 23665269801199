
.campaign-page-wrapper {
  width: 100%;

  .campaign-page {

    .campaign-name-header {
      font-size: 22px;
      color: black;
      font-weight: 600;
      display: flex;
      align-items: flex-start;
      gap: 0 10px;
      text-transform: capitalize;
    }

    .campaign-list-list {
      .campaign-list-list-header {
        color: black;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .campaign-list-list-item {
        margin-bottom: 5px;
      }
    }

    .campaign-review-label {
      font-size: 14px;
      font-weight: 600;
      color: black;
      margin: 10px 0 20px 0;

      &.large {
        font-size: 18px;
      }
    }

    .purchase-review {
      border-radius: 8px;
      border: 2px solid var(--ui-selector-testing-blue, #255FDF);
      margin: 40px 0;
      padding: 20px 40px;

      .final-price {
        font-size: 22px;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}

.campaign-status-pill {
  display: inline-block;
  padding: 4px 8px;
  align-items: center;
  border-radius: 14px;
  border: 1px solid var(--ui-selector-testing-blue, #255FDF);
  background: var(--ui-cards-white, #FFF);

  &.sent {
    border: 1px solid var(--ui-buy-active-green, #00A82F);

    .circle {
      background-color: var(--ui-buy-active-green, #00A82F);
    }
  }

  &.draft {
    border: 1px solid #ACACAC;

    .circle {
      background-color:  #ACACAC;
    }
  }

  &.cancelled {
    border: 1px solid #EA0029;

    .circle {
      background-color:  #EA0029;
    }
  }

  .content {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .circle {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: var(--ui-selector-testing-blue, #255FDF);
  }

  .status {
    color: var(--text-primary, #292B2E);
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
  }
}

.drawer-info-box {
  height: 84px;
}

// rsuite overrides
.campaign-accordion > .rs-panel.rs-panel-collapsible {
  width: 400px;
}

.dope-icon-card {
  margin: 10px 0;
  padding: 20px;
  display: flex;
  border: 2px solid #EAECEF;
  border-radius: 16px;
  cursor: pointer;

  &.selected {
    border: 2px solid var(--ui-selector-testing-blue, #255FDF);
    box-shadow: 0px 6px 6px 0px rgba(186, 186, 186, 0.25);
    background: var(--blue-grey-shade-light, rgba(188, 210, 225, 0.10));

    .dope-icon-card-icon {
      //background-color: var(--ui-selector-testing-blue, #255FDF);
      color: var(--ui-selector-testing-blue, #255FDF);;
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .dope-icon-card-icon {
    width: 60px;
    height: 60px;
    border-radius: 60px;
    //background-color: #F0F4F7;
    color: #494B4E;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    //box-shadow: 0px 6px 6px 0px rgba(186, 186, 186, 0.10);
    font-size: 48px;
  }

  .dope-icon-card-text {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .dope-icon-card-header {
      font-size: 18px;
      font-weight: 600;
      color: #292B2E;
      margin-bottom: 4px
    }

    .dope-icon-card-subheader {
      font-size: 14px;
      font-weight: 400;
      color: #6F6F6F;
    }
  }
}

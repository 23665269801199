.tooltip {
  &.wrapper {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    min-width: 225px;
    box-shadow: 0 6px 6px 0 rgba(186, 186, 186, 1);
    color: #292B2E;
  }

  &.header {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  &.item-label {
    font-size: 14px;
    font-weight: 600;
  }

  &.item-value {
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

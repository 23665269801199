.drawer-section-header {
  background: #F0F4F7;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 4px 8px;
}

.features-header {
  width: 340px;
}

.feature-tier-header {
  width: 80px;
  text-align: center;
  text-transform: capitalize;
}

.features-header-wrapper {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid #F0F4F7;
}

.account-logo {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.feature-button {
  width: 120px;
}



.selected {
  border: 2px solid #255FDF;
  background: #BCD2E11A;
}

.radio-tile {
  height: 100px;
  width: 137px;
  font-size: 14px;
  color: #000000;
}

.rs-radio-tile-mark, .rs-radio-tile-icon {
  display: none;
}

.tile-icon {
  font-size: 32px;
  color: #494B4E;

  &.selected-icon {
    color: #255FDF;
  }
}

.agency-client-credits {
  width: 100%;
  padding: 0 0.5rem;
  p {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}


.agency-drawer {
  &-header {
    display: flex;
    padding: 2rem 0;
    align-items: center;
    justify-content: space-between;
  }
}

.agency-card {
  display: flex;
  gap: 1rem;
  align-items: center;
}

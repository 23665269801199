

.geo-shapes-summary {
  padding: 10px 16px;
  background-color: #F0F4F7;
  border-radius: 8px;

  .geo-shapes-header {
    color: #8793A6;
    font-weight: 700;
  }

  .geo-shapes-count {
    color: #000;
    font-size: 30px;
    font-weight: 700;
    padding: 10px;
  }

  .geo-shapes-show-addresses {
    color: #3498ff;
  }

  &.empty {
    .geo-shapes-show-addresses, .geo-shapes-count {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.starting-address-prompt {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  padding: 0 40px;
  margin: 100px 0;
  color: #6F6F6F;
}

.geo-shape-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #EAECEF;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0;

  &.hovering {
    border-color: #3498ff;
  }

  .geo-shape-index {
    display: inline-block;
    $size: 20px;
    width: $size;
    height: $size;
    border-radius: $size;
    color: white;
    background: black;
    text-align: center;
    margin-right: 4px;
  }

  .geo-shape-type {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    color: #000;
    margin-right: 4px;
    min-width: 55px;
  }

  .geo-shape-count {
    min-width: 50px;
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #EAECEF;
    text-align: right;
    padding: 4px 8px;
    margin-right: 4px;
    font-size: 14px;
    color: #000;
  }

  .contacts-label {
    color: #6F6F6F;
    font-weight: 400;
  }
}

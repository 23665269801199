
.mailer-type-list {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #BCD2E11A;
  border-radius: 4px;

  .mailer-type-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 100%;
    cursor: pointer;

    &.selected {
      cursor: default;
      font-weight: bold;
      background-color: #FFFFFF;
      box-shadow: 0 2px 4px 0 #00000040;
      border-radius: 4px;
      height: 40px;
      margin-bottom: 2px;
      color: #255FDF;
      text-decoration: none;
    }
  }
}

.mailer-list {

  .mailer-item {
    margin-bottom: 40px;

    .mailer-image-wrapper {
      padding: 8px;
      background: #566E801A;
      border-radius: 8px;
    }

    .mailer-image {
      border-radius: 8px;
      width: 100%;
      height: 208px;
    }

    .front-image {
      margin-right: 2px;
    }

    .back-image {
      margin-left: 2px;
    }

    .mailer-text {
      margin-top: 4px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .mailer-name {
      font-weight: 600;
      font-size: 16px;
      color: #000000;
    }

    .mailer-type-text {
      font-size: 14px;
      color: #6F6F6F;
      font-weight: 600;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .mailer-approved-text {
      font-size: 11px;
      font-weight: 400;
      color: #6F6F6F;
      margin-left: 4px;
    }
  }
}

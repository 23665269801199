.purchase-summary {

  padding-top: 40px;
  padding-bottom: 40px;

  .purchase-header {
    font-size: 18px;
    font-weight: 600;
    color: #292B2E;

    &.medium {
      font-size: 16px;
    }

    &.small {
      font-size: 14px;
    }

    &.text {
      font-weight: 400;
    }
  }

  .help-text {
    margin-top: -15px;
    margin-bottom: 10px;
  }

  table {
    width: 100%;
    th {
      text-align: right;
      padding: 5px 5px 5px 0;
      font-size: 11px;
      font-weight: 700;
      color: #8793A6;

      &.product-header {
        text-align: left;
      }
    }

    td {
      text-align: right;
      padding: 5px 5px 5px 0;
      font-size: 14px;
      font-weight: 400;
      color: #6F6F6F;

      &.product-name {
        font-weight: 600;
        text-align: left;
      }
    }
  }
}

.margin-8-b {
  margin-bottom: 8px;
}


.product-pricing-tier.active-tier {
  font-weight: 700;
  transition: font-weight 0.2s ease-in-out;
}

.purchase-credits.disabled {
  opacity: 0.5;
  pointer-events: none;
}


.stripe-card-item {
  display: flex;
  gap: 10px;
  align-items: center;

  .stripe-card-last4 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: var(--text-primary, #292B2E);
    margin-bottom: 2px;
  }

  .stripe-card-brand {
    padding: 4px 12px;
    border: 2px solid var(--Grey, #D9D9D9);
    border-radius: 8px;
  }

  .stripe-card-expiry {
    font-size: 10px;
    font-weight: 400;
    color: var(--text-label-grey, #8793A6);
  }

  .stripe-card-update {
    text-align: right;
    flex-grow: 1;
  }
}

.design-overview {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;

  &-template {
    padding-bottom: 30px;
  }

  &-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  & .mini-preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    img {
      max-width: calc(50% - 1rem);
      border: 2px solid transparent;
      border-radius: 4px;
      cursor: pointer;

      &.selected {
        border: 2px solid rgba(52, 106, 225, 0.5);
      }

      &:first-child {
        &::before {
          content: "Front";
        }
      }
    }
  }

  & .postcard-overview,
  & .handwritten-card-overview {
    & .postcard-cover {
      max-width: 100%;
      margin-bottom: 1rem;
    }
  }

  .handwritten-card-overview-details {
    & .handwritten-card-text-preview {
      margin-bottom: 1rem;
    }
  }

  .design-activity-history {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;



    &-attachments {
      background-color: #f8fbfc;
      padding: 0.5rem;
      border-radius: 8px;

      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      span {
        display: flex;
        align-items: center;
      }

      a {
        text-decoration: underline;
      }
    }

    &-item {
      p {
        margin: 0.2rem 0;

        &:first-child {
          &::before {
            content: "";
            display: inline-block;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background-color: #255fdf;
            margin-right: 0.5rem;
          }
        }


      }

      & > *:not(:first-child) {
        margin-left: 1rem;
      }

      small {
        color: #6c757d;
      }
    }
  }
}

.design-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px 16px;
  padding-top: 10px;
}

.grid-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px;
  cursor: pointer;
}

.grid-item img {
  width: 100%;
  aspect-ratio: 145 /102;
  border-radius: 5px;
}

.badge {
  padding: 4px 8px;
  font-size: 11px;
  font-weight: 700;
  color: #255FDF;
  background: #F0F4F7;
  border-radius: 8px;
  width: fit-content;

  &.xs {
    padding: 3px 6px;
    font-size: 9px;
  }
}

.design-details-container {
  border-radius: 8px;
  background: rgba(188, 210, 225, 0.1);
  padding: 10px 20px;
  margin: 20px 0 0;
}

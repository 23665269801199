.slideover-page {
  .rs-form-group {
    margin: 1rem 0;
  }

  .label {
    margin-bottom: 0.3rem;
  }

  .page {
    padding: 60px;
  }

  input {
    max-width: 500px;
    width: 100%;
    display: block !important;
  }

  .dope-rich-text-editor {
    max-width: 500px;
    width: 500px;
  }
}

.slideover-drawer {
  .rs-drawer-body > h5 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .rs-drawer-body {
    font-size: 1rem;
  }
}

.rs-drawer-content {
  .rs-drawer-body > h5 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .rs-drawer-body {
    font-size: 1rem;
  }
}

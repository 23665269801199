.dope-rich-text-editor {
  width: 100%;
  border: 1px solid #d9d9d9;
  min-height: 500px;
  max-width: 800px;
  border-radius: 4px;
  border: 1px solid #EAECEF;

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1.4rem;
  }

  h5 {
    font-size: 1.2rem;
  }

  h6 {
    font-size: 1rem;
  }

  .ce-block__content {
    margin: 7px 11px;
  }

  .codex-editor--narrow .ce-toolbar__actions {
    right: unset;
    left: -57px;
  }

  .codex-editor--narrow .codex-editor__redactor {
    margin-right: 0;
  }
}

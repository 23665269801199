.calendar-cell {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.selected-calendar-cell, .selected-calendar-cell:hover {
  background: #255FDF;
  color: white;

  .rs-calendar-table-cell-content {
    background: #255FDF;
  }
}

.rs-calendar-header-title {
  width: 164px;
}

.rs-calendar-header-title, .rs-calendar-header-backward, .rs-calendar-header-forward, .rs-calendar-btn-today {
  border: 1px solid #EAECEF;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.rs-calendar-table-header-row {
  background: linear-gradient(0deg, #F0F4F7, #F0F4F7),
  linear-gradient(0deg, #EAECEF, #EAECEF);
}

.rs-calendar-table-header-cell-content {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.rs-calendar-table-header-cell {
  &:not(last-child) {
    border-right: 1px solid #EAECEF;
  }
}

.bordered-calendar-cell {
  border-right: 1px solid #EAECEF;
}

.dispatch-calendar-cell {
  position: relative;
  cursor: default;
  height: 100%;
  width: 100%;

  img {
    height: 56px;
    object-fit: cover;
  }

  .dispatch-status {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    text-transform: uppercase;
    padding: 2px 4px;
    color: white;
    background-color: gray;
    border-radius: 8px;
    font-weight: 700;
  }

  .dispatch-remove {
    position: absolute;
    top: -20px;
    right: 0;
    color: white;
    background-color: red;
    font-weight: 700;
    cursor: pointer;
    opacity: 0;
  }

  .dispatch-add {
    position: absolute;
    top: 2px;
    left: 0px;
    left: 50%;
    margin-left: -27.5px;
    padding: 16px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    display: flex;
    align-items: center;
    opacity: 0;
    background: white;
    border-radius: 8px;
  }

  &:hover {
    .dispatch-remove, .dispatch-add {
      transition: opacity 0.4s;
      opacity: 1;
    }
  }
}

.product-hot_lead {
  margin-top: 1rem;
  width: 100%;
  height: auto;

  .dope-button {
    padding: 0;
  }
}

.dispatch-mailer {
  display: flex;
}

.dispatch-mailer-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  flex: 2;
  overflow: hidden;
}

.disabled-calendar-cell {
  opacity: 0.5;
  pointer-events: none;
  background: #566E801A;
  color: #ACACAC;
}

.hot-lead-drawer-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.hot-lead-code {
  text-align: center;
  margin: 2rem 0;
}

.dispatch-hot-lead {
  color: #6F6F6F;
  font-size: small;
  font-family: sans-serif;

  display: flex;
  gap: 0.2rem;

  .dope-button {
    border-radius: 0;
    font-size: 10px;

    width: 100%;

    & > div {
      width: 100%;
    }
  }

  p {
    margin: 0;
    color: #6F6F6F;
    text-align: left;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

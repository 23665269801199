$error-red: #B40020;
$warning-yellow: #E9D543;
$primary-black: #292B2E;

.dope-message {

  border-radius: 8px;
  border-top: 8px solid $primary-black;
  box-shadow: 0px 6px 6px 0px rgba(186, 186, 186, 0.25);
  padding: 16px 10px;

  &.error {
    border-top-color: $error-red;

    .dope-message-content {

      .dope-message-icon * {
        fill: $error-red;
      }

      .dope-message-text {
        .dope-message-header {
          color: $error-red;
        }
      }
    }
  }

  &.warning {
    border-top-color: $warning-yellow;

    .dope-message-content {

      .dope-message-icon * {
        fill: $warning-yellow;
      }

      .dope-message-text {
        .dope-message-header {
          color: $primary-black;
        }
      }
    }
  }


  .dope-message-content {
    display: flex;
    gap: 10px;

    .dope-message-icon * {
      fill: $primary-black;
    }

    .dope-message-text {
      flex-grow: 1;

      .dope-message-header {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 4px;
        color: $primary-black;
      }

      .dope-message-body {
        color: $primary-black;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .dope-message-footer {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    button {
      min-width: 150px;
    }
  }
}


.tag-display {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 2px 6px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  font-size: 12px;

  &.selected {
    border-color: #292B2E;
  }

  &:last-child {
    margin-right: 0;
  }

  .tag-remove {
    margin-left: 6px;
    color: white;
    background-color: black;
    border: 1px solid black;
    font-weight: 700;
    font-size: 10px;
    padding: 2px;

    &:hover {
      color: black;
      background-color: white;
    }

    .rs-btn-icon.rs-btn-xs>.rs-icon {
      font-size: 10px;
    }
  }
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0;
  flex-direction: row;
}

.empty-tag-list {
  background: #BCD2E11A;
  padding: 12px;
  font-weight: 400;
  font-size: 14px;
  color: #6F6F6F;
  max-height: 600px;
  transition: max-height 0.8s ease-in, opacity 0.8s ease-in, visibility 0.8s ease-in;
  text-align: center;
  border-radius: 8px;

  &.hidden {
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    padding: 0;
    height: 0;
    transition: opacity 0.4s linear, visibility 0.4s linear, max-height 0.4s ease-out;
  }
}

.tag-search-results {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px;
  max-height: 600px;
  transition: max-height 0.4s ease-in;

  &.hidden {
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    padding: 0;
    transition: opacity 0.4s linear, visibility 0.4s linear, max-height 0.4s ease-out;
  }

  .list-divider {
    margin: 5px 0;
    text-transform: uppercase;
  }

  .tag-display {
    cursor: pointer;
  }
}

.dope-tags .search-wrapper {
  position: relative;

  .create-tag {
    position: absolute;
    right: 6px;
    top: 6px;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
    background: #255FDF;

    .rs-btn-icon.rs-btn-xs>.rs-icon {
      font-size: 10px;
    }
  }
}

